// @flow
// (Copyright) Confluent, Inc.

import React from 'react';
import styled from 'styled-components';
import Downshift from 'downshift';
import { navigate } from 'gatsby';

import useSearchPlugins from '../../../hooks/usePlugins';
// @TODO: may use image in the future
// import FrameImage from '../../FrameImage';
import { matchQuery } from '../../../models/plugin';

const MAX_RESULT_ITEMS = 10;

type Props = {
  initialInputValue: string,
  onSearch: (string) => void,
};

const handleSelect = (item) => {
  if (item) {
    navigate(item.path);
  }
};

const Search = ({ initialInputValue, onSearch }: Props) => {
  const [isOpen, setOpen] = React.useState(false);
  const [inputValue, setInputValue] = React.useState(initialInputValue);

  React.useEffect(() => {
    setInputValue(initialInputValue);
  }, [initialInputValue]);

  const items = useSearchPlugins();

  const handleKeyUp = (selectedItem) => (e) => {
    if (!selectedItem && e.key === 'Enter') {
      onSearch(e.target.value);
      setOpen(false);
    }
  };

  const handleStateChange = (state) => {
    if ('inputValue' in state) {
      setInputValue(state.inputValue);
    }

    if ('isOpen' in state) {
      setOpen(state.isOpen);
    }
  };

  return (
    <Downshift
      initialInputValue={initialInputValue}
      inputValue={inputValue}
      isOpen={isOpen}
      itemToString={(item) => (item ? item.title : '')}
      onSelect={handleSelect}
      onStateChange={handleStateChange}
      stateReducer={stateReducer}
    >
      {({
        getInputProps,
        getItemProps,
        getMenuProps,
        inputValue,
        highlightedIndex,
        selectedItem,
        getRootProps,
      }) => (
        <InputWrapper>
          <InputFrame {...getRootProps()}>
            <SearchIcon />
            <Input
              {...getInputProps({
                onKeyUp: handleKeyUp(selectedItem),
                onFocus: () => setOpen(true),
              })}
              placeholder="What plugin are you looking for?"
            />
            <ResultsHolder>
              <ItemWrapper {...getMenuProps()}>
                {isOpen && inputValue && inputValue.length > 1
                  ? items
                      .filter((item) => matchQuery(item, inputValue))
                      .slice(0, MAX_RESULT_ITEMS)
                      .map((item, index) => (
                        <Item
                          {...getItemProps({
                            key: item.slug,
                            index,
                            item,
                          })}
                          isHighlighted={highlightedIndex === index}
                          isSelected={selectedItem === item}
                          key={item.slug}
                        >
                          {/* @TODO: May use this in a future version

                          <PluginImage>
                            <FrameImage height={24} image={item.logo} width={24} />
                          </PluginImage>*/}
                          {item.title}
                        </Item>
                      ))
                  : null}
              </ItemWrapper>
            </ResultsHolder>
          </InputFrame>
        </InputWrapper>
      )}
    </Downshift>
  );
};

export default Search;

// Few customisations to keep text in the input on blur and outside click
const stateReducer = (state, changes) => {
  if (Downshift.stateChangeTypes.mouseUp === changes.type) {
    return {
      ...changes,
      selectedItem: null,
      highlightedIndex: null,
      inputValue: state.inputValue,
      isOpen: false,
    };
  } else if (Downshift.stateChangeTypes.blurInput === changes.type) {
    return {
      ...changes,
      selectedItem: null,
      highlightedIndex: null,
      inputValue: state.inputValue,
      isOpen: false,
    };
  }

  return changes;
};

const ResultsHolder = styled.div`
  position: relative;
`;

const ItemWrapper = styled.ul`
  box-shadow: var(--SHADOW_HOVER);
  background: var(--WHITE);
  margin: 0;
  padding: 0;
  position: absolute;
  left: 1px;
  right: 1px;
  top: -1px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  max-height: 450px;
  overflow-y: auto;
`;

const Item = styled.li`
  list-style: none;
  height: 32px;
  line-height: 32px;
  background: ${(props) => (props.isHighlighted ? 'var(--PRIMARY_LIGHTER_EVEN)' : 'initial')};
  opacity: ${(props) => (props.isHighlighted ? 0.65 : 1)};
  padding-left: 16px;
  margin: 0;
  cursor: pointer;
`;

/*
@TODO: May use this in a future version.

const PluginImage = styled.div`
  margin: 4px 8px 4px 8px;
  width: 24px;
  height: 24px;
  float: left;
`;
*/

const Input = styled.input`
  display: block;
  width: 100%;
  height: 100%;
  font-size: 18px;
  box-sizing: border-box;
  border-radius: 4px;
  padding-left: 64px;
  border: solid 1px var(--PRIMARY_DARK);
  background-color: var(--WHITE);

  ::placeholder {
    color: var(--DIM_LIGHTER_EVEN);
  }
`;

const InputWrapper = styled.div`
  height: 64px;
  margin: 36px auto 0 auto;
  max-width: 640px;
  padding: 0 24px;

  @media (max-width: 960px) {
    max-width: 524px;
  }
`;

const InputFrame = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const Icon = (props) => (
  <svg {...props} height="22" viewBox="0 0 16 16" width="22" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.76 8.41a5.717 5.717 0 10-2.384 2.367l4.504 4.504 2.376-2.377L10.76 8.41zM1.907 5.717c0-2.101 1.709-3.81 3.81-3.81s3.81 1.709 3.81 3.81a3.814 3.814 0 01-3.81 3.81 3.814 3.814 0 01-3.81-3.81z"
      fill="#A7A7A8"
      fillRule="evenodd"
    />
  </svg>
);

const SearchIcon = styled(Icon)`
  position: absolute;
  top: 21px;
  left: 21px;
`;
